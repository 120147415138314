



























































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'faq-view',
  data(): any {
    return {
      faqs: [
        {
          question: 'What will happen to my current subscription with AnimeLab?',
          answer: 'You now have a Funimation account instead! AnimeLab will close shortly, so your existing account will become a Funimation account. This means you can continue to enjoy the best of anime on the Funimation website and apps.',
        },
        {
          question: 'Do I need to sign up for a new subscription on Funimation?',
          answer: 'If you had an existing subscription on AnimeLab then you do not need to sign up for a new subscription on Funimation, as your AnimeLab subscription extends to Funimation.  If you did not have an active subscription on AnimeLab and would like to subscribe now, then you will need to subscribe on Funimation.',
        },
        {
          question: 'Will my subscription price change?',
          answer: 'No! Your monthly subscription price will not change and you will continue to be billed in your local currency.',
        },
        {
          question: 'How do I cancel my subscription?',
          answer: 'You can cancel your subscription in the My Account section of the Funimation website after logging in.',
        },
        {
          question: 'What should I do if I want to restart my AnimeLab subscription?',
          answer: 'AnimeLab is now Funimation and you can restart your subscription on the Subscription tab in the My Account section on the Funimation website.',
        },
      ],
      globalFooter: {
        social: [
          {
            title: 'facebook',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/facebook.svg',
            href: 'https://www.facebook.com/funimation',
            target: '_blank',
          },
          {
            title: 'twitter',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/twitter.svg',
            href: 'https://twitter.com/funimation',
            target: '_blank',
          },
          {
            title: 'youtube',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/youtube.svg',
            href: 'https://www.youtube.com/playlist?list=PLwazKLaN7rLid3rdNgjgEfUq4zda3ZBUK',
            target: '_blank',
          },
          {
            title: 'instagram',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/instagram.svg',
            href: 'https://www.instagram.com/funimation',
            target: '_blank',
          },
          {
            title: 'pinterest',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/pinterest.svg',
            href: 'https://www.pinterest.com/funimation',
            target: '_blank',
          },
          {
            title: 'discord',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/discord.svg',
            href: 'https://www.discord.gg/funimation',
            target: '_blank',
          },
          {
            title: 'tiktok',
            icon: 'https://static-assets.prd.funimation.com/Social_Icon_SVGs/tiktok.svg',
            href: 'https://www.tiktok.com/@funimation',
            target: '_blank',
          },
        ],
        mainNav: [
          {
            title: 'about funimation',
            href: 'https://www.funimation.com/about-us/',
          },
          {
            title: 'customer support',
            href: 'https://help.funimation.com/',
          },
          {
            title: 'terms of use',
            href: 'https://www.funimation.com/terms-of-use/',
          },
          {
            title: 'privacy policy',
            href: 'https://www.sonypictures.com/corp/privacy.html',
          },
        ],
        copyright: 'Funimation Global Group, LLC. All Rights Reserved',
      },
    };
  },
  created() {
    if(!this.isAUNZ) {
      window.location.assign('/');
    }
  },
  computed: {
    isAUNZ() {
      return (window.region === 'AU' || window.region === 'NZ');
    },
  },
  metaInfo() {
    return {
      title: "Funimation - FAQ",
    };
  },
});
